import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import Order from "../../../../../types/order.type";
import Component from "../../../../components";
import { FaCheck, FaEdit, FaFileDownload, FaMailBulk, FaRegFile, FaTimes, FaWhatsapp } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import Swal from "sweetalert2";
interface InfoFieldProps {
  title: string;
  value: string | number;
}
const InfoField: React.FC<InfoFieldProps> = ({ title, value }) => (
  <div className="header-item">
    <h4 className="header-title">{title}</h4>
    <h4 className="header-value">{value}</h4>
  </div>
);

const InfoFieldLarge: React.FC<InfoFieldProps> = ({ title, value }) => (
  <div className="header-itemlarge">
    <h4 className="header-title">{title}</h4>
    <h4 className="header-value">{value}</h4>
  </div>
);
function aplicarMascaraCpfCnpj(valor: string) {
  // Remove caracteres não numéricos
  const apenasNumeros = valor.replace(/\D/g, '');

  // Verifica se é CPF ou CNPJ e aplica a máscara correspondente
  if (apenasNumeros.length <= 11) { // CPF
    return apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else { // CNPJ
    return apenasNumeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }
}



function aplicarMascaraTel(valor: string) {
  // Remove caracteres não numéricos
  const apenasNumeros = valor.replace(/\D/g, '');

  if (apenasNumeros.length === 11) {
    return apenasNumeros.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }
  // Verifica se o número tem 10 dígitos (telefone com oito dígitos + DDD)
  else if (apenasNumeros.length === 10) {
    return apenasNumeros.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }
  // Retorna o número não formatado caso não tenha 10 ou 11 dígitos
  else {
    return apenasNumeros;
  }
}
const DetalharPedido: React.FC = () => {

  const navigate = useNavigate();
  const steps = ["Análise", "Processamento", "Faturamento"];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  let currentStepIndex = 0;


  const location = useLocation();

  const props: Order = location.state as Order;

  if (props.situacao.trim() === "Aberto") {
    currentStepIndex = 0;
  } else if (props.situacao.trim() === "Producao") {
    currentStepIndex = 1;
  }
  else if (props.situacao.trim() === "Faturado") {
    currentStepIndex = 2;
  }




  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };


  const handleDownload = async (pedidoid: number) => {
    setLoading(true);



    try {
      setLoading(true);
      await api.get('/pedidos/downloadbyrepres?pedidoid=' + pedidoid, { responseType: 'blob' }).then(response => {
        // Cria um URL para o Blob e define como href para um link, depois dispara um clique
        const urlBlob = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));

        const link = document.createElement('a');
        link.href = urlBlob;
        link.setAttribute('download', 'DanfePdf.zip'); // ou qualquer outro nome de arquivo
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      });


    } catch (error: any) {
      setLoading(false);
      setError(error);
    }
  };

  const handleDownloadCopiaPedido = async (pedidoid: number, tipo: string) => {
    setLoading(true);
    try {
      setLoading(true);
      await api.get('/relatorios/copia_pedido?pedido=' + pedidoid + '&&tipo=' + tipo, { responseType: 'blob' }).then(response => {
        // Cria um URL para o Blob e define como href para um link, depois dispara um clique
        const urlBlob = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

        const link = document.createElement('a');
        link.href = urlBlob;
        link.setAttribute('download', 'pedido_' + pedidoid + '.pdf'); // ou qualquer outro nome de arquivo
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      });


    } catch (error: any) {
      setLoading(false);
      setError("Não foi possível realizar o download da cópia do pedido.");
    }
  };

  const handleSendEmail = async (pedidoid: number) => {
    setLoading(true);
    try {
      setLoading(true);
      await api.get('/pedidos/enviar_documentos_email?pedidoid=' + pedidoid).then(response => {

        setLoading(false);
      });


    } catch (error: any) {
      setLoading(false);
      setError("Não foi possível realizar o envio.");
    }
  };

  const handleOpenWhatsapp = async (phoneNumber: string) => {
    // Constrói a URL para abrir o WhatsApp com o número desejado
    const whatsappUrl = `https://api.whatsapp.com/send?1=pt_BR&phone=55${phoneNumber}`;
    // Abre a URL em uma nova aba do navegador
    window.open(whatsappUrl, '_blank');

  };

  const handleConcluirPedido = async (pedidoid: number) => {
    setLoading(true);
    try {
      setLoading(true);
      await api.post('/pedidos/concluir_pedido?pedido=' + pedidoid).then(response => {

        setLoading(false);
      });


    } catch (error: any) {
      setLoading(false);
      setError("Não foi possível concluir o pedido.");
    }
  };

  const handleCancelarPedido = async (pedidoid: number) => {

    Swal.fire({
      title: 'Você tem certeza?',
      text: "Você deseja realmente cancelar este pedido?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',

      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          setLoading(true);
          api.post('/pedidos/cancelar_pedido?pedido=' + pedidoid).then(response => {
            setLoading(false);
          });

        } catch (error: any) {
          setLoading(false);
          setError("Não foi possível cancelar o pedido.");
        }
      }
    });


  };

  const handleAlterarPedido = async (order: Order) => {
    navigate(`/admin-home/alterarpedido/${order.id}`, { state: order });
  };


  const totalProd = props.items.reduce((acc, product) => acc + product.quantidade, 0);
  return (
    <div>

      <div >
        {loading && <Component.Shared.LoaderComponent overlay />}
        <div className="page-header">
          <h1 >DETALHES DO PEDIDO</h1>

          <div className="btn btn-primary button" onClick={() => {
            if (props.situacao.trim() === 'Orçamento') {
              handleCancelarPedido(props.id);
            } else {
              setError('Somente pedidos em orçamento podem ser cancelados.')
            }
          }}>
            <FaTimes size={18} />
            <span> Cancelar</span>
          </div>

          <div className="btn btn-primary button" onClick={() => {
            if (props.situacao.trim() === 'Orçamento') {
              handleAlterarPedido(props);
            } else {
              setError('Somente pedidos em orçamento podem ser alterados.')
            }
          }}>
            <FaEdit size={18} />
            <span> Alterar</span>
          </div>


          <div className="btn btn-primary button" onClick={() => {
            if (props.situacao.trim() === 'Orçamento') {
              handleConcluirPedido(props.id);
            } else {
              setError('Somente pedidos em orçamento podem ser concluídos.')
            }
          }}>
            <FaCheck size={18} />
            <span> Concluir pedido</span>
          </div>
          <div className="btn btn-primary button" onClick={() => handleDownloadCopiaPedido(props.id, props.tipo)}>
            <FaRegFile size={18} />
            <span> Visualizar</span>
          </div>
          <div className="btn btn-primary button" onClick={() => {
            if (props.situacao.trim() === 'Faturado') {
              handleDownload(props.id);
            } else {
              setError('Download disponível apenas para documentos faturados.')
            }
          }}>
            <FaFileDownload size={18} />
            <span> Baixar documentos</span>
          </div>


          <div className="btn btn-primary button" onClick={() => {
            if (props.situacao.trim() === 'Faturado') {
              handleSendEmail(props.id);
            } else {
              setError('Envio disponível apenas para documentos faturados.')
            }
          }}>
            <FaMailBulk size={18} />
            <span> Enviar por Email</span>
          </div>
          <div className="btn btn-primary button" onClick={() =>
            handleOpenWhatsapp(props.telefone)
          }>
            <FaWhatsapp size={18} color="green" />
            <span> Whatsapp</span>
          </div>

          {error ? (<Component.Shared.TextComponent value={error} size={12} color='red' margin={0} align='center' />
          ) : null}
        </div>

        <div className="header-container">

          <div className="header-row">
            <InfoField title="Pedido:" value={props.id} />
            <InfoField title="Data:" value={props.data || '-'} />
            <InfoField title="Nr.Nota:" value={props.documento || '-'} />
          </div>

          <div className="header-row">
            <InfoFieldLarge title="Cliente:" value={props.nome} />
            <InfoField title="CNPJ/CPF:" value={aplicarMascaraCpfCnpj(props.cnpj_cpf)} />
            <InfoField title="Telefone:" value={aplicarMascaraTel(props.telefone)} />
          </div>

          <div className="header-row ">
            <InfoFieldLarge title="Endereço:" value={props.endereco} />
            <InfoField title="Município/UF:" value={props.cidade + "/" + props.uf} />
          </div>

          <div className="header-row">
            <InfoField title="Tipo de Pedido:" value={props.descTipoPed} />
            <InfoField title="Condição de Pagamento:" value={props.condpagto || '-'} />
            <InfoField title="Documento:" value={props.doctoId} />

            <InfoField title="Código Interno:" value={props.cod_interno || '-'} />
            <InfoField title="Rastreio:" value={props.codigo_rastreio || '-'} />
          </div>
          <div className="header-row">
            <InfoField title="Situação:" value={props.situacao} />

          </div>
          <div className="header-row">
            <InfoField title="Vendedor:" value={props.vendedorDesc} />
            <InfoField title="Pedido feito pelo cliente:" value={props.feito_por_cliente === true ? 'Sim' : 'Não'} />
          </div>

        </div>


        <div className="progress-container">
          {steps.map((step, index) => (
            <div key={index} className={`progress-step ${index <= currentStepIndex ? "active" : ""}`}>
              <div className="progress-circle"></div>
              <p>{step}</p>
            </div>
          ))}

        </div>

        <div className="produtoscheckout">
          <h4>Itens do Pedido:</h4>
          {props.items.map(item => (
            <Component.Custom.ProductCard
              key={item.id}
              id={item.id}
              imagem={item.imagem}
              nome={item.nome}
              qtde={item.quantidade}
              preco={item.preco}
              desabilitarbotoes
              variacoes={item.variacoes}
              onRemove={() => { }} // Como você provavelmente não quer remover itens aqui, mantemos esta função vazia
            />
          ))}
        </div>

        <div className="header-container">
          <div className="header-row">
            <InfoField title="Qtde Itens:" value={totalProd} />
            <InfoField title="Valor total:" value={'R$' + props.totalPedido.toFixed(2)} />
          </div>
          <div className="header-row">
            <InfoField title="Observação:" value={props.observacao || '-'} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetalharPedido;