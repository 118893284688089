import { useEffect, useState } from "react";
import api from "../../../../services/api";
import Component from "../../../components";
import Order from "../../../../types/order.type";
import { useNavigate } from "react-router-dom";
import { FaCartPlus } from "react-icons/fa";
import { format, eachDayOfInterval, startOfMonth, endOfMonth } from 'date-fns';
const HistoricoPedidos: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [list, setList] = useState<Order[]>([]);
    const [loading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const navigate = useNavigate();
    const indexOfLastProduct = currentPage * itemsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
    const [selectedDate, setSelectedDate] = useState<Date | null>();
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        setSelectedDate(new Date());
    }, []);

    useEffect(() => {
        if (selectedDate) {
            gerarRelatorio();
        }
    }, [selectedDate]);

    const gerarRelatorio = async () => {
        setIsLoading(true);
        if (selectedDate) {

            const mesAno = format(selectedDate, 'MM/yyyy');

            await api.get(`/relatorios/listar_pedidos?mesAno=${mesAno}`
            ).then(response => {
                setList(response.data)
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
            })
        }
    };

    const handleRowClick = (order: Order) => {
        navigate(`/admin-home/detalhes/${order.id}`, { state: order });
    }


    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    return (
        <div>
            <div className="btn btn-primary button" onClick={() => navigate('/admin-home/addpedidos')} >
                <FaCartPlus size={18} />
                <span> Novo pedido</span>
            </div>
            <Component.Shared.TextComponent marginTop={0} value="Período" size={18} color='black' margin={0} align='flex-start' />

            <div className="divisor-container">
                <div className="divisor1">
                    <Component.Shared.MonthPicker texto="" onDateChange={handleDateChange} value={selectedDate} />
                    <input
                        type="text"
                        placeholder="Pesquisar por CNPJ/CPF"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>

            {loading && (
                <div className="product-container">
                    <Component.Shared.LoaderComponent overlay />
                </div>
            )}

            <Component.Shared.ListaPedidoCard onSelectPedido={handleRowClick} pedidos={list.filter(cli => {
                // Se searchTerm for vazio, inclua todos os itens.
                if (!searchTerm) return true;
                return cli.cnpj_cpf.toLowerCase().includes(searchTerm.toLowerCase());
            })
                .slice(indexOfFirstProduct, indexOfLastProduct)} />


            <Component.Custom.Pagination
                currentPage={currentPage}
                totalItems={list.length}
                setCurrentPage={handlePageChange}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
            />
        </div>
    );
}

export default HistoricoPedidos;